/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx, Box, Flex, Text, SxStyleProp } from "theme-ui";
import Layout from "../components/Layout";
import { titleSx } from "../components/style";
import "./prizeTable.scss";

const subheaderSx: SxStyleProp = { fontSize: 2, fontWeight: "bold " };

const HowToPlayPage = memo(function HowToPlayPage(): ReactElement {
  return (
    <Layout title="How to play">
      <Flex mx={4}>
        <Box sx={titleSx}>How to play</Box>
      </Flex>
      <Box
        mt={3}
        px={4}
        py={4}
        sx={{ borderRadius: "40px", backgroundColor: "#0B162F" }}
      >
        <Text sx={subheaderSx}>About the game:</Text>
        <br />
        <Text>
          Every <b>Tuesday at 9am PST</b> players can join the weekly Dapp
          Queens contest. Registration is open for exactly 3 hours, and
          submissions must be <b>mined by 12pm PST</b>
          in order to be considered. The player’s goal is to select the top 5
          tokens that they think will have the greatest increase in value over
          the course of a week. Tokens are ranked according to their change in
          value from the start of the contest at 9am PST on Tuesday to the end
          of the contest the following <b>Monday at 9am PST</b>, the rankings
          will be dynamically updated throughout the week for you to monitor.
          <br />
          Players must submit an entrance fee of 0.0404 ETH (~$10.10), with 0.04
          ETH going straight into the prize pool and 0.0004 ETH used as a
          maintenance fee.
          <br />
        </Text>
        <br />
        <Text sx={subheaderSx}>To enter the game:</Text>
        <ol>
          <li>Select 5 tokens</li>
          <li>
            Allocate your 1,000 fantasy ETH between the 5 tokens (you must
            allocate a minimum of 10 ETH per coin){" "}
          </li>
          <li>Connect your Metamask wallet</li>
          <li>Submit your selection to Dapp Queens with the entrance fee </li>
          <li>
            Win your share of the prize pool based on your token performace!{" "}
          </li>
        </ol>
        <Text
          sx={{
            ...subheaderSx,
            color: "orange.4",
            textAlign: "center",
          }}
        >
          Prize Distribution
          <p />
        </Text>
        <Flex sx={{ justifyContent: "center" }}>
          <PayoutTable />
        </Flex>
      </Box>
    </Layout>
  );
});
export default HowToPlayPage;

const hideOnSmallSx = { display: ["none", "none", "table-cell"] };

const PayoutTable = memo(function PayoutTable(): ReactElement {
  return (
    <table
      sx={{
        borderCollapse: "collapse",
        fontSize: ["8px", 0, 1],
        "th,td": {
          px: 3,
          py: 1,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "gray.5",
        },
        th: {
          backgroundColor: "gray.7",
          fontWeight: "normal",
          textAlign: "left",
        },
      }}
    >
      <thead>
        <tr>
          <th>Rank</th>
          <th>Entries</th>
          <th />
          <th />
          <th />
          <th />
          <th>Entries</th>
          <th sx={hideOnSmallSx} />
        </tr>
      </thead>
      <tbody>
        <tr sx={{ td: { fontWeight: "bold" } }}>
          <th />
          <td>1-5</td>
          <td>6-12</td>
          <td>13-18</td>
          <td>19-27</td>
          <td>18-36</td>
          <td>37-50</td>
          <td sx={hideOnSmallSx} />
        </tr>
        <tr>
          <th>1</th>
          <td>100%</td>
          <td>50%</td>
          <td>40%</td>
          <td>40%</td>
          <td>33%</td>
          <td>29%</td>
          <td sx={hideOnSmallSx}>Prize %</td>
        </tr>
        <tr>
          <th>2</th>
          <td />
          <td>30%</td>
          <td>30%</td>
          <td>23%</td>
          <td>20%</td>
          <td>18%</td>
          <td sx={hideOnSmallSx} />
        </tr>
        <tr>
          <th>3</th>
          <td />
          <td>20%</td>
          <td>20%</td>
          <td>16%</td>
          <td>15%</td>
          <td>13%</td>
          <td sx={hideOnSmallSx} />
        </tr>
        <tr>
          <th>4</th>
          <td />
          <td />
          <td>10%</td>
          <td>12%</td>
          <td>11%</td>
          <td>10%</td>
          <td sx={hideOnSmallSx} />
        </tr>
        <tr>
          <th>5</th>
          <td />
          <td />
          <td />
          <td>9%</td>
          <td>8%</td>
          <td>8%</td>
          <td sx={hideOnSmallSx} />
        </tr>
        <tr>
          <th>6</th>
          <td />
          <td />
          <td />
          <td />
          <td>7%</td>
          <td>7%</td>
          <td sx={hideOnSmallSx} />
        </tr>
        <tr>
          <th>7</th>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td>6%</td>
          <td sx={hideOnSmallSx} />
        </tr>
        <tr>
          <th>8</th>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td>5%</td>
          <td sx={hideOnSmallSx} />
        </tr>
        <tr>
          <th>9</th>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td>4%</td>
          <td sx={hideOnSmallSx}>Prize %</td>
        </tr>
      </tbody>
    </table>
  );
});
